body {
  position: relative;
  margin-left: 5px;

  background-color: #1b365d;
  color: #d0d3d4;
}

html * {
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  cursor: pointer;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: #d0d3d4;
}

#footer {
  padding-top: 4px;
  margin-left: 10px;
  margin-top: 25px;
  min-width: 93vw;
  border-top-style: solid;
  border-color: #d0d3d4;
  border-width: 10px;
  box-sizing: border-box;
}

.row1 {
  padding-top: 20px;
}

.buttons > div > button {
  width: 120px;
  height: 90px;
  margin: 5px;
  font-size: 70px;
  border-radius: 30px;
}

.moreButtons {
  padding-left: 20px;
  display:flexbox;
  justify-self: center;
  align-self: center;
}

.moreButtons > .buttons {
  min-width: 50px;
  display: grid;
  grid-template-columns: repeat(3, 150px);
  grid-auto-rows: minmax(1px, auto);
  grid-template-areas:
    "r1 r1 r1"
    "r2 r2 r2"
    "r3 r3 r3";
  justify-content: center;
}

.moreButtons > .buttons > .row1 {
  padding-left: 70px;
  grid-area: r1;
}

.moreButtons > .buttons > .row2 {
  grid-area: r2;
}

.moreButtons > .buttons > .row3 {
  padding-left: 70px;
  grid-area: r3;
}

.prevGuesses {
  font-size: 25px;
  margin: 0px;
  list-style: none;
}

.currentGuess {
  font-size: 50px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 5px;
  display: flexbox;
}

.currentScore {
  font-size: 30px;
}

.header {
  padding-top: 20px;
  padding-bottom: 15px;
  padding-left: 10px;
  border-bottom-style: solid;
  border-color: #d0d3d4;
  border-width: 10px;
  box-sizing: border-box;
}

.header > button {
  margin-right: 10px;
}

.overlay {
  position: absolute;
  z-index: 10;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 15px;
  padding-bottom: 55px;
  background-color: #081220;
  border-color: #d0d3d4;
  border-width: 10px;
  border-style: solid;
  border-radius: 25px;
  font-size: 24px;
  max-width: 400px;
  min-width: 300px;
  white-space: pre-wrap;
}

.header > button {
  background: local;
  border: none;
}

.exitButton {
  background: local;
  font-size: 32px;
  padding: 0px;
  color: #d0d3d4;
  height: 40px;
  width: 40px;
  position: sticky;
  margin-left: 95%;
  margin-top: 3%;
  margin-bottom: -10px;
  border: none;
}

.exitButton > p {
  font-weight: 600;
  position: absolute;
  margin: 0;
  left: 0;
  right: 0;
  top: 0;
}

.actionButtons > button {
  margin: 5px;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 32px;
  border-radius: 20px;
}
